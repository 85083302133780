import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "poposki_extended_contract_with_riga" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Тренер по физической подготовке из Северной Македонии Сашко Попоски прошёл путь с ФК «Рига» от самого первого титула чемпиона Латвии, и обе стороны не собирается на этом останавливаться. На этой неделе ФК «Рига» продлил договор со специалистом из Северной Македонии ещё на один сезон. За три сезона в тренерском составе ФК «Рига» три раза выиграл чемпионат Латвии и один раз стал обладателем Кубка Латвии. Мы желаем Сашко удачи, плодотворной работы и громких побед с ФК «Рига»!</p>
        </div>
      </div>
    )
  }
}